@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500;600;700;900&family=Preahvihear&display=swap');


:root {
  background-color: #11071F;

}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  background-color: #11071F;
  color: white;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


::selection {
  color: #11071F;
  background: #763CAC;
}

.invisible {
  transition: 150ms ease-in-out;
  transform: scaleX(0);
}

.header-text {
  transition: 300ms ease-in-out;
}

.header-text:hover {
  color: #b362db;
}

.header-text:hover .invisible {
  transform: scaleX(1);
  visibility: visible;
}


.change-lang-svg-icon {
  transition: 300ms ease-in-out;
}

.change-lang:hover .change-lang-icon .change-lang-svg-icon {
  fill: #b362db;
}





.radial-gradient-purple {
  border-radius: 33rem;
  background: radial-gradient(50% 50% at 50% 50%, #763CAC 0%, rgba(50, 15, 133, 0.00) 100%);
}



.hero-desc {
  font-family: 'Poppins', sans-serif;
}



:root {
  --clr-1: #130428;
  --clr-2: #B362DB;
  --clr-3: #451885;
  --clr-4: #763CAC;
  --clr-5: #190634;
}

.title-style {
  @apply text-[2.5rem] sm:text-[4rem] font-[900] text-ilyes-purple pb-5;
}

.career-bio-text {
  @apply font-[500] text-justify text-[0.8rem] sm:text-[1rem] lg:text-[1.25rem] font-[600] text-white;
}

.biography-text-decoration {
  @apply font-[600] sm:font-[700] text-justify text-[1rem] sm:text-[1.25rem] lg:text-[1.5rem];
}

.career-link-text {
  @apply text-black font-[700] text-[0.8rem] sm:text-[0.8rem] md:text-[1rem] sm:font-[600];
}

.cards-background {
  border-radius: 0.7975rem;
  background: linear-gradient(141deg, #130428 -4.73%, #B362DB 48.79%, #451885 71.99%, #763CAC 120.81%, #190634 213.56%);
  transition: 300ms ease-in-out;
  background-size: 150%;
  background-position: right;

}

.cards-background:hover {
  background-position: left;
}


.active {
  transition: 300ms ease-out;
}




.custom-hidden {
  opacity: 0;
  filter: blur(5px);
  transform: translateX(-100%);
  transition: all 700ms;
}

.custom-hidden-2 {
  opacity: 0;
  filter: blur(5px);
  transform: translateX(40%);
  transition: all 700ms;
}

.custom-hidden-3 {
  opacity: 0;
  filter: blur(5px);
  transform: translateY(40%);
  transition: all 700ms;
}

/* 
@media(prefers-reduced-motion) {
  .custom-hidden {
    transition: none;
  }
}
 */
.show {
  opacity: 1;
  filter: blur(0px);
  transform: translateX(0);
}


.show-after {
  opacity: 1;
  filter: blur(0px);
  transform: translateX(0);
  transition-delay: 400ms;
}

.show-hero-desc {
  opacity: 1;
  filter: blur(0px);
  transform: translateX(0);
  transition-delay: 1s;
}


.show-hero-animation {
  opacity: 1;
  filter: blur(0px);
  transform: translateX(0);
  transition-delay: 1.5s;
}


.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}


.career-bio {
  --border-width: 5px;
  position: relative;
  color: white;
  background: #222;
  border-radius: var(--border-width);
}

.career-bio::after {
  position: absolute;
  content: "";
  top: calc(-1 * var(--border-width));
  left: calc(-1 * var(--border-width));
  z-index: -1;
  width: calc(100% + var(--border-width) * 2);
  height: calc(100% + var(--border-width) * 2);
  background: linear-gradient(170deg, #130428 -4.73%, #B362DB 48.79%, #451885 71.99%, #763CAC 120.81%, #190634 213.56%);
  background-size: 300% 300%;
  background-position: 0 50%;
  border-radius: calc(2 * var(--border-width));
  animation: moveGradient 2s alternate infinite;
}


.career-bio:hover::after {
  animation: moveGradient 500ms alternate infinite;

}

@keyframes moveGradient {
  50% {
    background-position: 100% 50%;
  }
}



.send-message-border {
  border-radius: 2.5rem 0rem;
  border: 2px solid #B362DB;
  padding: 1rem 2.5rem;

}



.project-card-bg {
  background: rgb(118, 60, 172);
  background: radial-gradient(circle, rgba(118, 60, 172, 1) 49%, rgba(75, 33, 135, 1) 100%);
}


@media (min-width: 540px) {

  .padding-animated-text {
    padding-left: 11rem;
  }
}





/* Swiper Shit */



.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  color: #11071F;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
} */